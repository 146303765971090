<!-- =========================================================================================
	File Name: EdirOrder.vue
	Description: Редактирование заказа
========================================================================================== -->

<template>
  <div id="order-edit">
    <div v-if="!isEditOrderAllowed" class="vx-row">
      <vx-card
          title-color="#fff"
          card-background="danger"
          content-color="#fff">
          <p class="text-center font-bold">
            Редактирование заказа запрещено
          </p>
      </vx-card>
    </div>

    <div v-else class="vx-row">
      <div class="vx-col md:w-1/2 xl:w-2/3 w-full relative">
        <catalog :order="order" :root_category_id="rootCategoryID" :skip_update="false" ref="catalog" />
      </div>

      <div class="vx-col md:w-1/2 xl:w-1/3 w-full">
        <vs-table :data="orderItems" hoverFlat noDataText="Нет товаров в заказе"
          class="mb-4">
          <template slot="thead">
            <vs-th>Название</vs-th>
            <vs-th>Цена</vs-th>
            <vs-th>Количество</vs-th>
            <vs-th>Всего</vs-th>
            <vs-th>Удалить</vs-th>
          </template>
          <template slot-scope="{data}">
            <tbody>
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td>
                  <p class="product-name font-medium">{{ tr.name }}</p>
                </vs-td>
                <vs-td>
                  <p class="product-category">{{ tr.price }} руб.</p>
                </vs-td>
                <vs-td>
                  <vs-input-number min="1" :value="tr.item_amount" @input="updateItemQuantity($event, indextr)" class="inline-flex" />
                </vs-td>
                <vs-td>
                  <p class="product-category">{{ tr.total_price }} руб.</p>
                </vs-td>
                <vs-td>
                  <api-button 
                      :click="() => removeItemFromOrder(tr)"
                      :disabled="!isRemovePaymentsAllowed"
                      text-color="black" 
                      icon="close" 
                      type="flat">
                  </api-button>
                </vs-td>
              </vs-tr>
            </tbody>
          </template>
        </vs-table>
      
        <order-payments :order="order" :callback="fixPaymentsCallback" />

        <vx-card class="mt-4">
          <h3 class="font-semibold mb-3">Заказ # {{order.number}}</h3>
          <span class="text-grey">ID {{order.id}}</span>

          <vs-divider />

          <div class="flex justify-between mb-2">
            <span class="text-grey">Сумма</span>
            <span>{{order.original_total_price}} руб.</span>
          </div>
          <div class="flex justify-between mb-2">
            <span class="text-grey">Скидка на заказ</span>
            <span class="text-success"> {{order.discount_value}} руб.</span>
          </div>

          <div class="flex justify-between mb-2" v-if="(currentClientBonusesBalance >= orderTotalPrice) && currentClientBonusesBalance != 0">
              <span class="text-grey">Будет оплачено бонусами</span>
              <span class="text-success">{{ orderTotalPrice }} руб.</span>
          </div>
          <!-- <div class="flex justify-between mb-2" v-if="currentClientBonusesBalance < orderTotalPrice && currentClientBonusesBalance != 0">
              <span class="text-grey">Оплачено бонусами</span>
              <span class="text-success">{{ currentClientBonusesBalance }} руб.</span>
          </div> -->

          <vs-divider />

          <div class="flex justify-between font-semibold mb-3" v-if="currentClientBonusesBalance >= orderTotalPrice">
            <span>К оплате</span>
            <span>0 руб.</span>
          </div>
          <div class="flex justify-between font-semibold mb-3" v-if="currentClientBonusesBalance < orderTotalPrice">
            <span>К оплате</span>
            <!-- <span>{{orderTotalPrice - currentClientBonusesBalance }} руб.</span> -->
            <span>{{orderTotalPrice}} руб.</span>
          </div>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import catalog from './Catalog.vue'
import orderPayments from './eCommerce/OrderPayments.vue'

export default {
  data() {
    return {
      paymentActionText: "Добавить оплату"
    }
  },
  computed: {
    currentClientBonusesBalance() {
        return this.$store.state.activeClient.info.bonus;
    },
    order() {
      return this.$store.state.editedOrder;
    },
    orderItems() {
      return this.order.items;
    },
    orderTotalPrice() {
      return this.$store.getters['eCommerce/orderTotalPrice'](this.order)
    },
    rootCategoryID() {
      if (this.$acl.check('cashier')) return this.$globals.CATALOG_ROOT_CATEGORY_ID;
      return this.$globals.BAR_ROOT_CATEGORY_ID;
    }
  },
  asyncComputed: {
    isEditOrderAllowed: {
      async get() {
        return await this.$store.dispatch('isEditOrderAllowed', this.order);
      },
      watch: ['order.status']
    },
    async isMyOrder() {
      return await this.$store.dispatch('isMyOrder', this.order);
    },
    async isRemovePaymentsAllowed() {
      return await this.$store.dispatch('isRemovePaymentsAllowed', this.order);
    }
  },
  methods: {
      removeItemFromOrder(item) {
        let callback = () => { 
          this.$refs.catalog.getCatalogItems();
        };
        this.$store.dispatch('eCommerce/removeItemFromOrder', {item: item, order: this.order, callback: callback});
      },
      updateItemQuantity(item_amount, index) {
        let callback = () => { 
          this.$refs.catalog.getCatalogItems();
        };
        this.$store.dispatch("eCommerce/updateItemQuantity", {item_amount: item_amount, index: index, order: this.order, callback: callback});
      },
      fixPaymentsCallback() {
        this.$router.back()
      },
  },
  created() {
    if (Object.keys(this.order).length === 0) {
      this.$router.push('/');
    }
  },
  components: {
    catalog,
    orderPayments,
  }
}
</script>

<style lang="scss" scoped>
#order-edit {
  .item-view-primary-action-btn {
    color: #2c2c2c !important;
    background-color: #f6f6f6;
  }

  .item-name {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
  }
}
</style>
